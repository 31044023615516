export default {
  FETCH_SPECIMENS: "FETCH_SPECIMENS",
  FETCH_SPECIMENS_BEGIN: "FETCH_SPECIMENS_BEGIN",
  FETCH_SPECIMENS_SUCCESS: "FETCH_SPECIMENS_SUCCESS",
  FETCH_SPECIMENS_FAILURE: "FETCH_SPECIMENS_FAILURE",

  CREATE_SPECIMEN: "CREATE_SPECIMEN",
  CREATE_SPECIMEN_BEGIN: "CREATE_SPECIMEN_BEGIN",
  CREATE_SPECIMEN_SUCCESS: "CREATE_SPECIMEN_SUCCESS",
  CREATE_SPECIMEN_FAILURE: "CREATE_SPECIMEN_FAILURE",

  WORKBENCH_ADD_SPECIMEN: "WORKBENCH_ADD_SPECIMEN",
  WORKBENCH_REMOVE_SPECIMEN: "WORKBENCH_REMOVE_SPECIMEN",

  SHOW_SPECIMENS: "SHOW_SPECIMENS",
  SHOW_SEARCH: "SHOW_SEARCH",

  FETCH_MY_HERBARIUM: "FETCH_MY_HERBARIUM",
  FETCH_MY_HERBARIUM_BEGIN: "FETCH_MY_HERBARIUM_BEGIN",
  FETCH_MY_HERBARIUM_SUCCESS: "FETCH_MY_HERBARIUM_SUCCESS",
  FETCH_MY_HERBARIUM_FAILURE: "FETCH_MY_HERBARIUM_FAILURE",
  SET_FOCUS: "SET_FOCUS",

  // CABINET STUFF

  NEW_CABINET: "NEW_CABINET",

  EDIT_CABINET: "EDIT_CABINET",
  EDIT_CABINET_SAVE: "EDIT_CABINET_SAVE",
  EDIT_CABINET_SAVE_BEGIN: "EDIT_CABINET_SAVE_BEGIN",
  EDIT_CABINET_SAVE_SUCCESS: "EDIT_CABINET_SAVE_SUCCESS",
  EDIT_CABINET_SAVE_FAILURE: "EDIT_CABINET_SAVE_FAILURE",
  EDIT_CABINET_CANCEL: "EDIT_CABINET_CANCEL",

  REMOVE_CABINET: "REMOVE_CABINET",
  REMOVE_CABINET_SUCCESS: "REMOVE_CABINET_SUCCESS",
  REMOVE_CABINET_FAILURE: "REMOVE_CABINET_FAILURE",

  // FOLDER STUFF
  NEW_FOLDER: "NEW_FOLDER",

  EDIT_FOLDER: "EDIT_FOLDER",
  EDIT_FOLDER_SAVE: "EDIT_FOLDER_SAVE",
  EDIT_FOLDER_SAVE_BEGIN: "EDIT_FOLDER_SAVE_BEGIN",
  EDIT_FOLDER_SAVE_SUCCESS: "EDIT_FOLDER_SAVE_SUCCESS",
  EDIT_FOLDER_SAVE_FAILURE: "EDIT_FOLDER_SAVE_FAILURE",
  EDIT_FOLDER_CANCEL: "EDIT_FOLDER_CANCEL",

  REMOVE_FOLDER: "REMOVE_FOLDER",
  REMOVE_FOLDER_SUCCESS: "REMOVE_FOLDER_SUCCESS",
  REMOVE_FOLDER_FAILURE: "REMOVE_FOLDER_FAILURE",

  FOLDER_ADD_SPECIMEN: "FOLDER_ADD_SPECIMEN",
  FOLDER_ADD_SPECIMEN_SUCCESS: "FOLDER_ADD_SPECIMEN_SUCCESS",
  FOLDER_ADD_SPECIMEN_FAILURE: "FOLDER_ADD_SPECIMEN_FAILURE",

  FOLDER_REMOVE_SPECIMEN: "FOLDER_REMOVE_SPECIMEN",
  FOLDER_REMOVE_SPECIMEN_SUCCESS: "FOLDER_REMOVE_SPECIMEN_SUCCESS",
  FOLDER_REMOVE_SPECIMEN_FAILURE: "FOLDER_REMOVE_SPECIMEN_FAILURE"
};
